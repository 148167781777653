<template>
  <div id="manager-detail" class="manager-detail">
    <template v-if="!loading && list.length > 0">
      <div class="info">
        <div class="header">
          <div class="avatar">
            <img :src="list[0].oppositeHeadImgUrl">
          </div>
          <div class="cont">
            <div class="name">{{ list[0].userRealName }}</div>
            <div class="des">
              <div class="item">
                已放款金额：
                <span>{{ list[0].alreadyLoan }}万</span>
              </div>
              <div class="item">
                还需金额：
                <span>{{ list[0].needLoan }}万</span>
              </div>
            </div>
          </div>
        </div>
        <div class="list">
          <van-swipe ref="swipe" class="my-swipe" indicator-color="#999999" @change="swiperChange">
            <div v-for="item in list" :key="item.id">
              <van-swipe-item>
                <div class="swiper-item">
                  <div class="top">
                    <div class="status">
                      <img v-if="item.processStatus == 1" :src="icon1" alt="">
                      <img v-else-if="item.processStatus == 6" :src="icon2" alt="">
                      <img v-else :src="icon3" alt="">
                    </div>
                    <div class="header">
                      <div class="logo">
                        <img :src="item.funderLogo" class="img">
                      </div>
                      <div class="des">
                        <div class="name">{{ item.replaceName }}</div>
                        <div class="type">
                          <span class="it">{{ item.creditTypeName }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="group">
                        <div class="name">额度范围(万)</div>
                        <div class="des">
                          <span class="num">{{ item.loanFrom }}-{{ item.loanTo }}</span>
                        </div>
                      </div>
                      <div class="group">
                        <div class="name">月利率(%)</div>
                        <div class="des">
                          <span class="num">{{ item.interestRateFrom }}-{{ item.interestRateTo }}</span>
                        </div>
                      </div>
                      <div class="group">
                        <div class="name">贷款期限(月)</div>
                        <div class="des">
                          <span class="num">{{ item.creditLimitFrom }}-{{ item.creditLimitTo }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="group">
                      <div class="title">还款方式</div>
                      <div class="cont">{{ item.paymentTypeList && item.paymentTypeList.join('，') }}</div>
                    </div>
                    <div class="group">
                      <div class="title">利率说明</div>
                      <div class="cont table">
                        <div class="tr">
                          <div class="td">贷款期限(月)</div>
                          <div
                            class="td"
                            style="color: #FF7700"
                          >{{ item.creditLimitFrom }}-{{ item.creditLimitTo }}</div>
                        </div>
                        <div class="tr">
                          <div class="td">月利率(%)</div>
                          <div
                            class="td"
                            style="color: #FF7700"
                          >{{ item.interestRateFrom }}-{{ item.interestRateTo }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-swipe-item>
            </div>
          </van-swipe>
        </div>
        <div class="notice">
          <div class="head">须知></div>
          <div class="cont">
            <div v-if="serviceFeeRateExplain" class="p">1、{{ serviceFeeRateExplain }}</div>
            <div v-if="extraCharge" class="p">2、{{ extraCharge }}</div>
          </div>
        </div>
      </div>
      <div class="service">
        <div class="header">服务保障</div>
        <div class="content">
          <div class="list">
            <div
              v-for="(item, index) in serviceList"
              :key="item.id"
              class="item"
              :class="index == serviceActive ? 'active' : ''"
              @click="serviceChange(index)"
            >
              <div class="icon">
                <img v-if="index == 0" src="@/assets/match/match_01.png" class="img">
                <img v-if="index == 1" src="@/assets/match/match_02.png" class="img">
                <img v-if="index == 2" src="@/assets/match/match_03.png" class="img">
                <img v-if="index == 3" src="@/assets/match/match_04.png" class="img">
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
          <div class="notice">{{ serviceNotice[serviceActive] }}</div>
        </div>
      </div>
      <div class="progress">
        <div class="header">贷款进度</div>
        <div class="step">
          <div v-for="item in progress.nodes" :key="item.id" class="item" :class="{fail: item.data.operation != 1}">
            <div class="status">
              <img v-if="item.data.operation == 1" :src="successIcon" alt="">
              <img v-else :src="failIcon" alt="">
            </div>
            <div class="cont">
              <div class="title">{{ item.nodeName }} <span v-if="item.data.loanAmount && item.data.loanAmount > 0">终审金额：{{ item.data.loanAmount }}W</span> </div>
              <div class="des">
                {{ item.data.desc }}
              </div>
            </div>
            <div v-if="item.nodeType == '06'" class="notice" @click="showServicePrice">
              <van-icon name="info-o" /> <span>服务费</span>
            </div>
            <div v-if="item.nodeType == '07' && list[0].orderSuccess === 1" class="success-btn">
              <div v-if="list[0].hasComment == 1" class="bt" @click="goSalesmanInfo()">
                <div class="ic"><img :src="icon4" alt=""></div>
                <div class="tt">已评价</div>
              </div>
              <div v-else class="bt" @click="goComment">
                <div class="ic"><img :src="icon4" alt=""></div>
                <div class="tt">评价</div>
              </div>
              <div class="bt" @click="goShare">
                <div class="ic"><img :src="icon5" alt=""></div>
                <div class="tt">分享</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contact">
        <div class="btn" @click="goChat">继续沟通</div>
      </div>
    </template>
    <van-popup v-model="show" round position="bottom">
      <div class="popup">
        <div class="top">
          <div class="title">平台服务费</div>
          <div class="btn" @click="closePopup"><van-icon name="cross" /></div>
        </div>
        <div class="center">
          <div class="group">
            <div class="label">产品名称：</div>
            <div class="text">{{ serviceFee.productName }}</div>
          </div>
          <div class="group">
            <div class="label">贷款方式：</div>
            <div class="text">{{ serviceFee.creditType }}</div>
          </div>
          <div class="group">
            <div class="label">放款金额 (元)：</div>
            <div class="text">{{ (serviceFee.completedLoan/100) }}</div>
          </div>
          <div class="group">
            <div class="label">服务费类型：</div>
            <div class="text">{{ serviceFee.serviceFeeType }}</div>
          </div>
          <div class="group">
            <div class="label">实际服务费(元)：</div>
            <div class="text">{{ serviceFee.actualFee / 100 }}</div>
          </div>
          <div class="group">
            <div class="unit">{{ serviceFee.completedLoan/100 }}*{{ (serviceFee.ratio/100).toFixed(2) }}={{ serviceFee.actualFee/100 }}</div>
          </div>
          <div class="group">
            <div class="label">有无卡券：</div>
            <div class="text coupon">
              {{ serviceFee.existCoupons ? serviceFee.existCoupons : '暂无卡券' }}
              <span v-if="serviceFee.percentOff === 0">({{ serviceFee.existCoupons ? `满${serviceFee.priceAvailable/100}元抵${serviceFee.priceOff/100}元` : '暂无优惠' }})</span>
              <span v-else>({{ serviceFee.existCoupons ? `满${serviceFee.priceAvailable/100}元享${(serviceFee.percentOff/10).toFixed(1)}折` : '暂无优惠' }})</span>
            </div>
          </div>
          <div class="group">
            <div class="label">优惠金额 (元)：</div>
            <div class="text">{{ serviceFee.discountAmount/100 }}</div>
          </div>
          <div class="group">
            <div class="label">最终服务费 (元)：</div>
            <div class="text final">{{ serviceFee.finalFee/100 }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="title">温馨提示：</div>
          <div class="cont">
            1. 放款金额越高优惠越大哦~<br>
            2. 服务费采用代扣的形式在您的银行卡中自行扣除！
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import successIcon from '@/assets/success@2x.png'
import failIcon from '@/assets/fail@2x.png'
import icon1 from '@/assets/loan/loanto@2x.png'
import icon2 from '@/assets/loan/loancancel.png'
import icon3 from '@/assets/loan/loanno.png'
import icon4 from '@/assets/user/evaluate@2x.png'
import icon5 from '@/assets/user/wechat@2x.png'
import { mapGetters } from 'vuex'
import { getProductDetailByOrderId, processList, getplatformFee, verifyIfItSTheFirstTime } from '@/api/product'
import { getOssUrl } from '@/utils/oss'
import { setShare } from '@/utils/wx.config'
export default {
  data() {
    return {
      isOrderFirstTime: null,
      loading: false,
      show: false,
      list: [],
      swiperActive: 0,
      serviceFeeRateExplain: '', // 选中产品的须知1
      extraCharge: '', // 选中产品的须知2
      serviceList: [
        { title: '信息透明', id: 1 },
        { title: '收费透明', id: 2 },
        { title: '服务透明', id: 3 },
        { title: '隐私保障', id: 4 }
      ],
      serviceActive: 0,
      serviceNotice: [
        '拒绝信息不对称，保证产品无猫腻',
        '拒绝黑中介，收费标准公开、透明',
        '匹配专业经理人，保证服务进度全程可监控',
        '拒绝信息泄漏，坚决保护用户个人隐私'
      ],
      params: {
        orderId: ''
      },
      progress: {},
      serviceFee: {},
      isChat: false,
      successIcon,
      failIcon,
      icon1,
      icon2,
      icon3,
      icon4,
      icon5
    }
  },
  computed: {
    ...mapGetters([
      'userInfos',
      'shareUrls'
    ])
  },
  async mounted() {
    this.loading = true
    const { orderId } = this.$route.params
    this.params.orderId = orderId
    this.isChat = this.$route.query && this.$route.query.isChat
    await this.getList()
    await this.getProgress()
    await this.getplatformFee()
    if (this.list[0].orderSuccess === 1) {
      await this.verifyIfItSTheFirstTime()
    }
    await this.initWx()
    this.loading = false
  },
  methods: {
    closePopup() {
      this.show = false
    },
    showServicePrice() {
      this.show = true
    },
    swiperChange(index) {
      this.serviceFeeRateExplain = this.list[index].serviceFeeRateExplain
      this.extraCharge = this.list[index].extraCharge
      this.swiperActive = index
      this.getProgress()
      this.getplatformFee()
    },
    serviceChange(index) {
      this.serviceActive = index
    },
    goChat() {
      if (this.isChat) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'chatRoom', params: { your: this.list[0].eaUserName }})
      }
    },
    goSalesmanInfo() {
      this.$router.push({ name: 'salesmanInfo', params: { saleId: this.$route.query.saleId }})
    },
    goShare() {
      this.$toast(
        {
          message: '请点击右上角三个点，选择转发给朋友进行分享！'
        }
      )
    },
    goComment() {
      const details = {
        saleHeadImgUrl: this.list[0].headImgUrl,
        realName: this.list[0].realName,
        loan: this.list[0].demandLoan,
        creditTypeTitle: this.list[0].creditTypeName,
        orderId: this.params.orderId,
        saleId: this.$route.query.saleId
      }
      const products = this.list.map(item => {
        return item.replaceName
      })
      details.products = products
      this.$router.push({ name: 'userComment', params: { details }})
    },
    async initWx() {
      await this.$store.dispatch('wx/initWxConfig', window.location.href.split('#')[0])
      await this.$store.dispatch('user/getShareUrls', {
        keys: [
          'invite_share'
        ]
      })
      const link = this.shareUrls.invite_share
      setShare({
        title: '用最少的钱，贷更多的钱',
        desc: '猫嘀嘀推崇客户至上的理念，让您体验极致贷款服务',
        link,
        imgUrl: 'https://www.maodidikeji.com/user/assets/logo.png'
      })
    },
    async verifyIfItSTheFirstTime() {
      const res = await verifyIfItSTheFirstTime({ orderId: this.params.orderId })
      this.isOrderFirstTime = res.datas && res.datas.eject || 1
      if (this.isOrderFirstTime === 2) {
        this.$dialog.confirm({
          message: '经理人已助您完成了贷款，赶快去对Ta的服务做一个评价吧！',
          cancelButtonText: '残忍拒绝',
          cancelButtonColor: '#666666',
          confirmButtonText: '前去评价',
          confirmButtonColor: '#FF7700'
        })
          .then(() => {
          // on confirm
            this.goComment()
          })
          .catch(() => {
          // on cancel
          })
      }
    },
    async getplatformFee() {
      if (this.list.length === 0) return
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getplatformFee({ orderId: this.list[this.swiperActive].orderId, userId: this.userInfos.userId })
      this.serviceFee = res.datas
      loading.clear()
    },
    async getProgress() {
      if (this.list.length === 0) return
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await processList({ orderId: this.list[this.swiperActive].orderId })
      this.progress = res.datas || {}
      loading.clear()
    },
    async getList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getProductDetailByOrderId(this.params)
      const list = res.datas || []
      const { serviceFeeRateExplain, extraCharge } = list[0] || {}
      for (let i = 0; i < list.length; i++) {
        list[i].funderLogo = await getOssUrl(list[i].funderLogo)
        list[i].oppositeHeadImgUrl = await getOssUrl(list[i].oppositeHeadImgUrl)
      }
      this.list = list
      this.serviceFeeRateExplain = serviceFeeRateExplain
      this.extraCharge = extraCharge
      loading.clear()
    }
  }
}
</script>

  <style lang="scss" scoped>
.manager-detail {
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  background: #fcfcfc;
  .info {
    .header {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 16px;
      }
      .cont {
        flex: 1;
        .name {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #2e3135;
        }
        .des {
          display: flex;
          padding-top: 10px;
          font-size: 14px;
          color: #2e3135;
          .item {
            flex: 1;
            font-size: 12px;
            color: #666666;
            span {
              font-size: 14px;
              color: #2e3135;
            }
          }
        }
      }
    }
    .list::v-deep {
      margin: 0 -16px;
      .my-swipe {
        padding-bottom: 40px;
      }
    }
    .notice {
      .head {
        padding: 10px 0;
        font-size: 12px;
        color: #f86767;
      }
      .cont {
        line-height: 18px;
        font-size: 12px;
        color: #666666;
      }
    }
  }
  .service {
    padding-top: 20px;
    .header {
      padding: 16px 0;
      font-size: 16px;
      color: #2e3135;
      font-weight: bold;
    }
    .content {
      .list {
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          padding: 10px 0;
          text-align: center;
          &.active {
            background: #eff1f3;
          }
          .icon {
            .img {
              width: 45px;
              height: 45px;
            }
          }
          .title {
            padding-top: 10px;
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .notice {
        padding: 9px 18px;
        font-size: 14px;
        color: #32465a;
        background: #eff1f3;
      }
    }
  }
  .countor {
    position: relative;
    top: 40px;
    padding-top: 15px;
    .header {
      padding: 16px 0;
      font-size: 16px;
      color: #2e3135;
      font-weight: bold;
    }
    .group {
      display: flex;
      .item {
        flex: 1;
        .title {
          padding: 10px 0;
          font-size: 12px;
          color: #999999;
        }
        .input {
          .text {
            width: 100%;
            padding: 0 8px;
            font-size: 18px;
            color: #2e3135;
            height: 44px;
            line-height: 44px;
            border-radius: 8px;
            box-sizing: border-box;
            border: 0;
            background: #f5f5f7;
          }
        }
      }
    }
    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      .item::v-deep {
        width: 105px;
        height: 62px;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        .van-picker__confirm {
          color: #ff7700;
        }
        .van-picker-column__item--selected {
          color: #ff7700;
          background: #fef4e8;
        }
        .title {
          font-size: 12px;
          color: #999999;
        }
        .cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 12px;
          .tt {
            font-size: 14px;
            color: #2e3135;
          }
          .ct {
            .icon {
              width: 6px;
              height: 10px;
            }
          }
        }
      }
    }
    .bottom {
      padding: 24px;
      .btn {
        width: 100%;
        height: 47px;
        line-height: 47px;
        font-size: 14px;
        color: #ff7700;
        text-align: center;
        border-radius: 47px;
        border: 1px solid #ff7700;
      }
    }
  }
  .process {
    position: relative;
    top: 60px;
    .header {
      padding: 20px 0;
      font-size: 16px;
      color: #2e3135;
      font-weight: bold;
    }
    .content {
      padding: 20px 50px;
      background: #ffffff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      .group {
        position: relative;
        padding-top: 55px;
        padding-bottom: 20px;
        .head {
          position: absolute;
          top: 0;
          left: -65px;
          width: 110px;
          height: 48px;
          font-size: 14px;
          color: #ffffff;
          padding: 15px 0 0 30px;
          box-sizing: border-box;
          background-size: 100%;
          background-image: url("~@/assets/match/step@2x.png");
        }
        .cont {
          .title {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            color: #2e3135;
            .round {
              width: 7px;
              height: 7px;
              margin-right: 10px;
              border-radius: 7px;
              background: #ffb367;
            }
          }
          .des {
            padding-top: 20px;
            padding-left: 17px;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
          }
        }
      }
    }
  }
  .contact {
    padding: 40px 0;
    .btn {
      height: 47px;
      line-height: 47px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      background: linear-gradient(136deg, #ffb367 0%, #ff7700 100%);
      border-radius: 24px;
    }
  }
}
.swiper-item {
  position: relative;
  padding: 200px 16px 16px 16px;
  margin: 0 32px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  .top {
    position: absolute;
    top: 0;
    left: -25px;
    width: 361px;
    height: 196px;
    padding: 39px;
    box-sizing: border-box;
    background-size: 100%;
    background-image: url("~@/assets/match/card@2x.png");
    .status {
      position: absolute;
      right: 15px;
      top: 10px;
      img {
        width: 75px;
      }
    }
    .header {
      display: flex;
      align-items: center;
      .logo {
        width: 50px;
        height: 50px;
        .img {
          width: 50px;
        }
      }
      .des {
        flex: 1;
        padding-left: 10px;
        .name {
          width: 160px;
          font-size: 16px;
          font-weight: bold;
          color: #2e3135;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .type {
          padding-top: 10px;
          .it {
            padding: 3px 10px;
            margin-right: 5px;
            font-size: 12px;
            color: #18c47c;
            background: #dcf6eb;
            border-radius: 4px;
          }
        }
      }
    }
    .content {
      display: flex;
      padding-top: 10px;
      .group {
        flex: 1;
        text-align: center;
        .name {
          font-size: 12px;
          color: #999999;
        }
        .des {
          padding-top: 10px;
          font-size: 12px;
          color: #2e3135;
          .num {
            font-size: 18px;
            font-weight: bold;
            color: #ff7700;
          }
        }
      }
    }
  }
  .bottom {
    .group {
      display: flex;
      font-size: 14px;
      color: #32465a;
      margin-bottom: 16px;
      .title {
        width: 65px;
        flex: none;
        color: #91a0b4;
      }
      .table {
        flex: 1;
        border-radius: 4px;
        border: 1px solid #cccccc;
        .tr {
          display: flex;
          line-height: 30px;
          border-bottom: 1px solid #cccccc;
          &:last-child {
            border: 0;
          }
          .td {
            flex: 1;
            text-align: center;
            border-right: 1px solid #cccccc;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
}
.progress {
  .header {
    padding: 20px 0;
    font-size: 16px;
    color: #2e3135;
    font-weight: bold;
  }
  .step {
    padding-left: 15px;
    .item {
      position: relative;
      display: flex;
      padding: 10px 0;
      &.fail::before {
        border-left-color: #F86767;
      }
      &.fail {
        .cont {
          .title {
            color: #F86767;
          }
        }
      }
      &::before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 13px;
          width: 1px;
          height: 100%;
          border-left: 2px dashed #18c47c;
          z-index: 9;
      }
      &:first-child:before {
        height: 50%;
        bottom: 0;
        top: inherit;
      }
      &:last-child:before {
        height: 50%;
        top: 0;
        bottom: inherit;
      }
      .status {
        z-index: 99;
        img {
          display: block;
          width: 28px;
          height: 28px;
        }
      }
      .cont {
        flex: 1;
        padding: 6px 0 0 10px;
        .title {
          font-size: 14px;
          color: #2E3135;
          span {
            margin-left: 10px;
            padding: 4px 10px;
            border-radius: 4px;
            color: #FF7700;
            font-size: 12px;
            background: #FFF3E6;
          }
        }
        .des {
          padding-top: 10px;
          font-size: 12px;
          color: #999999;
        }
      }
      .notice {
        display: flex;
        align-items: flex-start;
        padding: 6px 0 0 6px;
        color: #91A0B4;
        i {
          font-size: 16px;
        }
        span {
          padding-left: 5px;
        }
      }
    }
  }
}
.popup {
  padding: 0 16px;
  .top {
    display: flex;
    align-items: center;
    padding: 16px 0;
    font-size: 16px;
    .title {
      flex: 1;
      text-align: center;
    }
    .btn {
      width: 20px;
      color: #cccccc;
    }
  }
  .center {
    .group {
      display: flex;
      align-items: center;
      padding: 8px 0;
      font-size: 14px;
      .label {
        color: #999999;
      }
      .unit {
        padding: 5px 10px;
        font-size: 12px;
        color: #FF7700;
        border-radius: 5px;
        background: #FFF3E6;
      }
      .text.coupon {
        color: #2697FF;
        span {
          padding-left: 5px;
          font-size: 12px;
          color: #F86767;
        }
      }
      .text.final {
        font-size: 16px;
        color: #FF7700;
        font-weight: bold;
      }
    }
  }
  .bottom {
    font-size: 12px;
    color: #999999;
    line-height: 18px;
    .cont {
      padding: 10px 0;
    }
  }
}
.success-btn {
  display: flex;
  .bt {
    margin: 0 7px;
    .ic {
      text-align: center;
      img {
        width: 20px;
      }
    }
    .tt {
      padding-top: 5px;
      font-size: 10px;
      color: #666666;
    }
  }
}
</style>

